import React, { useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { Container } from "src/components/Container";
import { Link } from "src/components/Link";
import { Typography } from "src/components/Typography";
import { PathEnum } from "src/enums/PathEnum";

import * as styles from "./MakeItLoudBlock.module.scss";

const query = graphql`
  query {
    allStrapiExternalLinks {
      edges {
        node {
          id
          Title
          Image {
            localFile {
              publicURL
            }
          }
          Link
        }
      }
    }
  }
`;

export const MakeItLoadBlock: React.FC = () => {
  const ITEMS_COUNT = 2;

  const data = useStaticQuery(query);
  const blogs = data.allStrapiExternalLinks.edges;
  const [showedItems] = useState(ITEMS_COUNT);

  return (
    <Container rootClassName={styles.root}>
      <Typography variant="h3" color="white" animate>
        Даем шума
      </Typography>

      <div className={styles.row}>
        {blogs.slice(0, showedItems).map((blog) => (
          <div className={styles.event} key={blog.node.id}>
            <a href={blog.node.Link} target="_blank" rel="noreferrer">
              <div
                className={styles.image}
                style={{
                  backgroundImage: `url(${blog.node.Image[0].localFile.publicURL})`,
                }}
              />

              <Typography color="white" variant="h5" className={styles.title}>
                {blog.node.Title}
              </Typography>

              {/*               <div className={styles.links}>
                <Link hoverFill="none">митап</Link>
                <Link hoverFill="none">дизайн</Link>
              </div> */}
            </a>
          </div>
        ))}
      </div>

      <Link
        size="m"
        showArrow
        rounded={false}
        className={styles.showMoreLink}
        href={PathEnum.MakeItLoud}
      >
        Посмотреть всё
      </Link>
    </Container>
  );
};
