import React, { FC } from "react";

import cn from "classnames";
import { useWindowScroll } from "src/hooks";

import { Logo } from "../Logo";
import * as styles from "./Banner.module.scss";

interface Props {
  fullScreen?: boolean;
  withLogo?: boolean;
  backgroundImage?: string;
  backgroundColor?: string;
  className?: string;
}

export const Banner: FC<Props> = ({
  children,
  fullScreen,
  withLogo,
  backgroundImage,
  backgroundColor,
  className,
}) => {
  const scrolled = useWindowScroll();

  return (
    <div
      className={cn(className, styles.root, fullScreen && styles.fullScreen)}
      style={{
        backgroundColor,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
      }}
    >
      {children}

      {withLogo && (
        <Logo
          className={cn(styles.logo, scrolled && styles.logoScrolled)}
          size="l"
        />
      )}
    </div>
  );
};
