// extracted by mini-css-extract-plugin
export var grid = "TastyEndingBlock-module--grid--23KeF";
export var boxRoot = "TastyEndingBlock-module--boxRoot--Bp6Dy";
export var boxRoot1 = "TastyEndingBlock-module--boxRoot1--3ErOe";
export var boxRoot2 = "TastyEndingBlock-module--boxRoot2--2f2Ws";
export var boxRoot3 = "TastyEndingBlock-module--boxRoot3--pks8w";
export var boxRoot4 = "TastyEndingBlock-module--boxRoot4--LEXM5";
export var box = "TastyEndingBlock-module--box--3E4r0";
export var text = "TastyEndingBlock-module--text--3CMLb";
export var images = "TastyEndingBlock-module--images--2pXZf";
export var carousel = "TastyEndingBlock-module--carousel--2tlOK";