import React from "react";

import { Carousel } from "src/components/Carousel";
import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";

import slide1 from "./1.jpg";
import slide2 from "./2.jpg";
import * as styles from "./AdultsOnlyBlock.module.scss";

const IMAGES = [slide1, slide2];

export const AdultOnlyBlock: React.FC = () => {
  return (
    <div>
      <Container>
        <Typography variant="h3" animate>
          Все по-взрослому
        </Typography>

        <Typography>
          Работаем в кросс-функциональных командах и придерживаемся гибких
          подходов к разработке — SCRUM и SAFe. Это помогает нам быстро внедрять
          изменения и выводить продукт на рынок. Релизим новые фичи в среднем
          каждые две недели.
        </Typography>
      </Container>

      <Container rootClassName={styles.carouselRoot}>
        <Carousel>
          {IMAGES.map((image, index) => (
            <div className={styles.box} key={index}>
              <img src={image} alt="office" />
            </div>
          ))}
        </Carousel>
      </Container>

      <Container>
        <div className={styles.directions}>
          <div>
            <Typography variant="h5">
              Дизайн
            </Typography>

            <Typography variant="caption">
              Создаем дизайн, который дарит эмоции, используя современные
              инструменты: Figma и Adobe.
            </Typography>
          </div>

          <div>
            <Typography variant="h5">
              Стек
            </Typography>

            <Typography variant="caption">
              .NET Core, Rabbit MQ, PostgreSQL, MSSQL, Redis, Hangfire. Для
              CI/CD используем Teamcity и Openshift.
            </Typography>
          </div>

          <div>
            <Typography variant="h5">
              Исследования
            </Typography>

            <Typography variant="caption">
              Проводим исследования и проверку гипотез, разрабатываем
              дизайн-систему, благодаря которой обеспечиваем унификацию
              интерфейсов, сокращая сроки на разработку.
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};
