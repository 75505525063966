import React, { ReactChild, ReactNode, useEffect, useState } from "react";

import cn from "classnames";
import { Carousel as BasicCarousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as styles from "./Carousel.module.scss";

interface Props {
  children: ReactChild[] & ReactNode;
  className?: string;
  autoplay?: boolean;
}

export const Carousel: React.FC<Props> = ({ children, className }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    if (!animate) {
      setTimeout(() => setAnimate(true), 100);
    }
  }, [animate]);

  return (
    <div className={cn(styles.root, className)}>
      <BasicCarousel
        autoPlay={animate}
        interval={5000}
        infiniteLoop
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
      >
        {children}
      </BasicCarousel>
    </div>
  );
};

Carousel.defaultProps = {
  children: [],
  autoplay: true,
};
