import React from "react";

import cn from "classnames";
import { Carousel } from "src/components/Carousel";
import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";

import smileImage from "./smile.svg";
import * as styles from "./TastyEndingBlock.module.scss";
import toothImage from "./tooth.svg";

const Box1 = () => {
  return (
    <Container
      className={styles.box}
      rootClassName={cn(styles.boxRoot, styles.boxRoot1)}
    >
      <div className={styles.images}>
        <img src={smileImage} alt="smile" />
        <img src={toothImage} alt="tooth" />
        <img src={smileImage} alt="smile" />
        <img src={toothImage} alt="tooth" />
      </div>

      <Typography variant="h5" color="white" className={styles.text}>
        ДМС со стоматологией в партнерских клиниках твоего города
      </Typography>
    </Container>
  );
};

const Box2 = () => {
  return (
    <Container
      className={styles.box}
      rootClassName={cn(styles.boxRoot, styles.boxRoot2)}
    >
      <Typography color="white" className={styles.text}>
        Партнерские скидки от 30 до 50% в фитнес-клубах Казани с первого дня
        работы
      </Typography>
    </Container>
  );
};

const Box3 = () => {
  return (
    <Container
      className={styles.box}
      rootClassName={cn(styles.boxRoot, styles.boxRoot3)}
    >
      <Typography color="white" className={styles.text}>
        Оплата участия во внешних профильных мероприятиях
      </Typography>
    </Container>
  );
};

const Box4 = () => {
  return (
    <Container
      className={styles.box}
      rootClassName={cn(styles.boxRoot, styles.boxRoot4)}
    >
      <Typography color="white" className={styles.text}>
        Присоединяйся к нашим командам по волейболу и мини-футболу, или лови
        дзен на йоге с корпоративным тренером
      </Typography>
    </Container>
  );
};

export const TastyEndingBlock: React.FC = () => {
  return (
    <Container>
      <Typography variant="h3" animate>
        Плюшки на десерт
      </Typography>

      <div className={styles.grid}>
        {Box1()}
        {Box2()}
        {Box3()}
        {Box4()}
      </div>

      <Carousel className={styles.carousel}>
        {Box1()}
        {Box2()}
        {Box3()}
        {Box4()}
      </Carousel>
    </Container>
  );
};
