import React from "react";

import { Carousel } from "src/components/Carousel";
import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";

import slide1 from "./images/1.jpg";
import slide2 from "./images/2.jpg";
import slide3 from "./images/3.jpg";
import slide4 from "./images/4.jpg";
import slide5 from "./images/5.jpg";
import slide6 from "./images/6.jpg";
import slide7 from "./images/7.jpg";
import slide8 from "./images/8.jpg";
import * as styles from "./SuperTeamBlock.module.scss";

const IMAGES = [slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8];


export const SuperTeamBlock: React.FC = () => {
  return (
    <Container>
      <Typography variant="h3" animate>
        И нашей супер-команды
      </Typography>
      <Carousel>
          {IMAGES.map((image, index) => (
            <div className={styles.box} key={index}>
              <img src={image} alt="team" />
            </div>
          ))}
        </Carousel>
    </Container>
  );
};
