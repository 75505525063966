import { RefObject } from "react";
import { createRef, useEffect, useState } from "react";

export const useWidthChange = (
  inputRef?: RefObject<HTMLDivElement>,
  delta = 0
) => {
  const [width, setWidth] = useState(0);
  const ref = inputRef || createRef<HTMLDivElement>();

  useEffect(() => {
    const handleScroll = () => {
      const { current } = ref;

      if (current) {
        window.requestAnimationFrame(() =>
          setWidth(
            (1.2 -
              current.getBoundingClientRect().bottom /
                (window.innerHeight + delta * window.innerHeight)) *
              100
          )
        );
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [ref]);

  return { ref, width };
};
