// extracted by mini-css-extract-plugin
export var wrapper = "StatisticsBlock-module--wrapper--O8WGa";
export var carousel = "StatisticsBlock-module--carousel--lhRo4";
export var row = "StatisticsBlock-module--row--1XkA4";
export var box = "StatisticsBlock-module--box--2VpPv";
export var box1 = "StatisticsBlock-module--box1--2LJ0s";
export var box2 = "StatisticsBlock-module--box2--33bzT";
export var box3 = "StatisticsBlock-module--box3--nGyUc";
export var box4 = "StatisticsBlock-module--box4--31P9h";
export var box5 = "StatisticsBlock-module--box5--1NLBj";
export var box6 = "StatisticsBlock-module--box6--3OzmC";
export var box7 = "StatisticsBlock-module--box7--G2k3m";
export var box8 = "StatisticsBlock-module--box8--319Bc";
export var box9 = "StatisticsBlock-module--box9--2-KfI";
export var hands = "StatisticsBlock-module--hands--1AZOT";
export var line = "StatisticsBlock-module--line--vpOVG";
export var arrowBox = "StatisticsBlock-module--arrowBox--1s37q";
export var arrowRoot = "StatisticsBlock-module--arrowRoot--3dafT";
export var arrow = "StatisticsBlock-module--arrow--1Q-qT";