// extracted by mini-css-extract-plugin
export var root = "ProductsBlock-module--root--1nVDF";
export var grid = "ProductsBlock-module--grid--Lk3Vj";
export var boxRoot = "ProductsBlock-module--boxRoot--3Vpx7";
export var mobileVisible = "ProductsBlock-module--mobileVisible--_S6_L";
export var desktopVisible = "ProductsBlock-module--desktopVisible--3Y2bX";
export var box1Root = "ProductsBlock-module--box1Root--3Ea1w";
export var box2Root = "ProductsBlock-module--box2Root--2PM3y";
export var box3Root = "ProductsBlock-module--box3Root--1dIml";
export var box1 = "ProductsBlock-module--box1--2YOk9";
export var box3 = "ProductsBlock-module--box3--akoeb";
export var text = "ProductsBlock-module--text--1JTbb";
export var box2 = "ProductsBlock-module--box2--1F3Zs";
export var image = "ProductsBlock-module--image--2WdEu";
export var carousel = "ProductsBlock-module--carousel--3YsLW";
export var gradient_wrapper = "ProductsBlock-module--gradient_wrapper--1znh6";
export var box = "ProductsBlock-module--box--33Cdb";
export var allProductsBtn = "ProductsBlock-module--allProductsBtn--1MS4C";