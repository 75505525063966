import React from "react";

import cn from "classnames";
import { Carousel } from "src/components/Carousel";
import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";
import { PathEnum } from "src/enums/PathEnum";

import box3Image from "./box-3.png";
import * as styles from "./ProductsBlock.module.scss";

const Box1 = () => {
  return (
    <div className={cn(styles.boxRoot, styles.box1Root)}>
      <Container
        rootClassName={styles.gradient_wrapper}
        className={cn(styles.box, styles.box1)}
      >
        <Typography variant="h4" color="white" className={styles.text}>
          Ак Барс Онлайн
        </Typography>

        <Typography color="white" className={styles.text}>
          Флагманский продукт. Входит в тройку лучших банковских приложений
          России по версии агентства Markswebb
        </Typography>
      </Container>
    </div>
  );
};

const Box2 = () => {
  return (
    <div className={cn(styles.boxRoot, styles.box2Root)}>
      <Container
        rootClassName={styles.gradient_wrapper}
        className={cn(styles.box, styles.box2)}
      >
        <Typography variant="h4" color="white" className={styles.text}>
          ABCloud
        </Typography>

        <Typography color="white" className={styles.text}>
          Система автоматизации ЦОД и оркестрации
        </Typography>
      </Container>
    </div>
  );
};

const Box3 = () => {
  return (
    <div className={cn(styles.boxRoot, styles.box3Root)}>
      <Container
        rootClassName={styles.gradient_wrapper}
        className={cn(styles.box, styles.box3)}
      >
        <Typography variant="h4" color="white" className={styles.text}>
          Aimee
        </Typography>
        <img src={box3Image} alt="aimee" className={styles.image} />

        <Typography color="white" className={styles.text}>
          Aimee — единая фронтальная система для работы сотрудников
          контакт-центра
        </Typography>
      </Container>
    </div>
  );
};

export const ProductsBlock: React.FC = () => {
  return (
    <div>
      <Container>
        <Typography variant="h3" animate>
          Делаем это с помощью наших продуктов
        </Typography>
      </Container>
      <Carousel className={styles.carousel}>
        <Box1 />
        <Box2 />
        <Box3 />
      </Carousel>
      <Container rootClassName={styles.root} className={styles.grid}>
        <Box1 />
        <Box2 />
        <Box3 />
        <a
          href={PathEnum.Projects}
          className={cn(styles.allProductsBtn, styles.desktopVisible)}
        >
          все продукты
        </a>
      </Container>
      <a
        href={PathEnum.Projects}
        className={cn(styles.allProductsBtn, styles.mobileVisible)}
      >
        все продукты
      </a>
    </div>
  );
};
