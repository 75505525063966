import React from "react";

import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";

import * as styles from "./TeachAndLearnBlock.module.scss";

export const TeachAndLearnBlock: React.FC = () => {
  return (
    <Container rootClassName={styles.root}>
      <Typography variant="h3" animate color="white">
        Учимся и учим
      </Typography>

      <div className={styles.grid}>
        <div className={styles.rocks} />

        <div>
          <Typography color="white">
            Мы создаем все условия для профессионального роста: применяем
            практики менторства, разрабатываем индивидуальные планы развития,
            следим за тем, чтобы никому не было скучно.
          </Typography>

          <Typography color="white">
            Каждый год любой сотрудник может пройти курс по его профилю за счёт
            компании.{" "}
          </Typography>

          <Typography color="white">
            Можно обучиться дополнительному направлению с нуля у наших лучших
            специалистов на курсах Security Champions, Scrum Master Champion.
          </Typography>

          <Typography color="white">
            Активно участвуем в топ-конференциях: от DotNext и PyConf до FinNext
            и Finnovate.
          </Typography>
        </div>
      </div>
    </Container>
  );
};
