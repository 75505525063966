import React from "react";

import { Banner } from "src/components/Banner";
import { Container } from "src/components/Container";
import { PageWrapper } from "src/components/PageWrapper";
import { Typography } from "src/components/Typography";

import backgroundImage from "./banner.png";
import { AdultOnlyBlock } from "./blocks/AdultsOnlyBlock";
import { MakeItLoadBlock } from "./blocks/MakeItLoudBlock";
import { ProductsBlock } from "./blocks/ProductsBlock";
import { StatisticsBlock } from "./blocks/StatisticsBlock";
import { SuperTeamBlock } from "./blocks/SuperTeamBlock";
import { TastyEndingBlock } from "./blocks/TastyEndingBlock";
import { TeachAndLearnBlock } from "./blocks/TeachAndLearnBlock";
import { WorkBlock } from "./blocks/WorkBlock";
import * as styles from "./Home.module.scss";

export const Home: React.FC = () => {
  return (
    <PageWrapper
      headerProps={{ colorChangeable: true }}
      title="Ak Bars Digital"
      topAutoScroll
    >
      <Banner backgroundImage={backgroundImage} fullScreen withLogo />
      <Container>
        <Typography variant="h3" animate>
          Дружим людей
          <br className={styles.br} /> с деньгами
        </Typography>

        <Typography className={styles.text}>
          Ak Bars Digital – двигатель технологического развития Ак Барс Банка.
          Разрабатываем и внедряем решения для финансовых и повседневных
          (lifestyle) задач клиентов банка.
        </Typography>

        <div className={styles.grid}>
          <Typography>
            Ак Барс Банк
            <br className={styles.br} /> на рынке
            <span className={styles.numColor}> 29</span> лет
          </Typography>

          <Typography>
            Ak Bars Digital
            <br className={styles.br} />
            <span className={styles.numColor}> 6</span> лет
          </Typography>

          <Typography>
            <span className={styles.numColor}> 500+</span> человек в команде
            <br className={styles.br} /> из
            <span className={styles.numColor}> 60</span> городов России
          </Typography>
        </div>

        <Typography>
          Нашими продуктами и сервисами пользуется 3,5 млн. людей, а Ак Барс
          Онлайн уже три года подряд в топе рейтинга самых удобных банковских
          приложений по версии Markswebb.
        </Typography>

        <Typography>
          Мы ориентированы на создание цифровой экосистемы и выпуск новых
          технологичных продуктов, чтобы дать возможность клиентам банка
          воспользоваться сразу несколькими сервисами.
        </Typography>
      </Container>

      <ProductsBlock />

      <AdultOnlyBlock />

      <SuperTeamBlock />

      <StatisticsBlock />

      {/* <VacanciesBlock /> */}

      <MakeItLoadBlock />

      <WorkBlock />

      <TeachAndLearnBlock />

      <TastyEndingBlock />
    </PageWrapper>
  );
};
