import React from "react";

import { Carousel } from "src/components/Carousel";
import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";
import { useMediaQuery } from "src/hooks";
import { tablet } from "src/styles/variables.module.scss";

import img from "./img-1.jpg";
import img2 from "./img-2.jpg";
import * as styles from "./WorkBlock.module.scss";

// todo: Переписать логику
interface ITextBlock {
  isDesktop: boolean;
}

const TextBlock: React.FC<ITextBlock> = ({ isDesktop }) => (
  <div className={styles.imageText}>
    {!isDesktop && <div className={styles.overlay}></div>}
    <img src={img} alt="slide-1" />
    {!isDesktop && (
      <div className={styles.carouselText}>
        <Typography color="white">
          Казань — отличный город для жизни, Иннополис — мечта IT-интроверта c
          лесом и горнолыжкой по соседству. Поможем с переездом в любой из двух
          на выбор.
        </Typography>
        <Typography color="white">
          Впрочем, можно просто остаться дома с пледом, котиком и какао. Главное
          чтобы проекты двигались.
        </Typography>
      </div>
    )}
  </div>
);
const TextBlockTwo: React.FC<ITextBlock> = ({ isDesktop }) => (
  <div className={styles.imageText}>
    {!isDesktop && <div className={styles.overlay}></div>}
    <img src={img2} alt="slide-1" />
    {!isDesktop && (
      <div className={styles.carouselText}>
        <Typography color="white">
          Казань — отличный город для жизни, Иннополис — мечта IT-интроверта c
          лесом и горнолыжкой по соседству. Поможем с переездом в любой из двух
          на выбор.
        </Typography>
        <Typography color="white">
          Впрочем, можно просто остаться дома с пледом, котиком и какао. Главное
          чтобы проекты двигались.
        </Typography>
      </div>
    )}
  </div>
);

export const WorkBlock: React.FC = () => {
  const isDesktop = useMediaQuery(`(max-width: ${tablet})`, false, true);

  return (
    <Container>
      <Typography variant="h3" animate>
        Работа для нас — это не только работа
      </Typography>
      <div className={styles.grid}>
        <div>
          <Typography variant="h4">Живи и работай где хочешь</Typography>
          {isDesktop && (
            <>
              <Typography>
                Казань — отличный город для жизни, Иннополис — мечта
                IT-интроверта c лесом и горнолыжкой по соседству. Поможем с
                переездом в любой из двух на выбор.
              </Typography>
              <Typography>
                Впрочем, можно просто остаться дома с пледом, котиком и какао.
                Главное, чтобы проекты двигались.
              </Typography>
            </>
          )}
        </div>
        <div>
          <Carousel>
            <TextBlock isDesktop={isDesktop} />
            <TextBlockTwo isDesktop={isDesktop} />
          </Carousel>
        </div>
      </div>
    </Container>
  );
};
