import React, { createRef, useEffect, useState } from "react";

import cn from "classnames";
import { Carousel } from "src/components/Carousel";
import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";
import { useWidthChange } from "src/hooks/useWidthChange";

import "src/styles/breakpoints.scss";
import AB from "./images/ab.png";
import ArrowIcon from "./images/arrow.svg";
import CompIcon from "./images/comp.svg";
import EyesIcon from "./images/eyes.svg";
import hand from "./images/hand.svg";
import ArrowsIcon from "./images/many arrows.svg";
import SmileIcon from "./images/smile.svg";
import Vector from "./images/Vector.png";
import * as styles from "./StatisticsBlock.module.scss";

const Box1: React.FC = () => {
  return (
    <div className={cn(styles.box1, styles.box)}>
      <div className={styles.row}>
        <img src={SmileIcon} alt="SmileIcon" />
        <img src={SmileIcon} alt="SmileIcon" />
        <img src={SmileIcon} alt="SmileIcon" />
        <img src={SmileIcon} alt="SmileIcon" />
      </div>
      <Typography color="white" variant="h3">
        500+ сотрудников
      </Typography>
    </div>
  );
};

const Box2: React.FC = () => {
  return (
    <div className={cn(styles.box2, styles.box)}>
      <Typography color="white" variant="h5">
        За 3 года провели 40+ митапов и воркшопов. Развиваем местное
        ИТ-комьюнити
      </Typography>
      <img src={Vector} alt="" />
    </div>
  );
};

const Box3: React.FC = () => {
  return (
    <div className={cn(styles.box3, styles.box)}>
      <img src={EyesIcon} width="121px" height="105px" alt="eyes" />

      <Typography color="white" variant="h5">
        70% сотрудников работают удаленно
      </Typography>
    </div>
  );
};

const Box4: React.FC = () => {
  const { ref: handsRef, width: handsWidth } = useWidthChange();

  return (
    <div className={cn(styles.box4, styles.box)}>
      <div
        className={styles.hands}
        ref={handsRef}
        style={{
          width: `${handsWidth}%`,
        }}
      >
        <img src={hand} alt="hand" />
        <div className={styles.line} />
        <img src={hand} alt="hand" />
      </div>

      <Typography color="white" variant="h5">
        География команды — 50+ городов России. С нами удобно работать из любого
        часового пояса: от Хабаровска до Калининграда
      </Typography>
    </div>
  );
};

const Box5: React.FC = () => {
  return (
    <div className={cn(styles.box5, styles.box)}>
      <div className={styles.row}>
        <img src={CompIcon} alt="CompIcon" />
        <img src={CompIcon} alt="CompIcon" />
        <img src={CompIcon} alt="CompIcon" />
        <img src={CompIcon} alt="CompIcon" />
      </div>
      <Typography color="white" variant="h5">
        2 раза в год проводим хакатон, на котором можно реализовать собственный
        проект и получить ценные призы
      </Typography>
    </div>
  );
};

const Box6: React.FC = () => {
  return (
    <div className={cn(styles.box6, styles.box)}>
      <Typography variant="h5" color="white">
        2 технических и 1 перфоманс ревью для составления индивидуального плана
        развития и повышения грейда
      </Typography>
      <img src={ArrowsIcon} alt="" />
    </div>
  );
};

const Box7: React.FC = () => {
  return (
    <div className={cn(styles.box7, styles.box)}>
      <Typography variant="h5" color="white">
        Находимся в Казани. Наш город входит в топ 3 лучших мегаполисов России
        для карьерного роста по версии <span>Superjob</span>
      </Typography>
    </div>
  );
};

const Box8: React.FC = () => {
  return (
    <div className={cn(styles.box8, styles.box)}>
      <Typography variant="h5" color="white">
        За два года более 130 человек прошли обучение за счет компании
      </Typography>
      <img src={AB} alt="" />
    </div>
  );
};

const Box9: React.FC = () => {
  const arrowRef = createRef<HTMLDivElement>();

  const [angle, setAngle] = useState(0);
  const { width: arrowWidth } = useWidthChange(arrowRef, 0.8);

  useEffect(() => {
    const calcAngle = () => {
      const { current } = arrowRef;

      if (current) {
        const { width, height } = current.getBoundingClientRect();

        const z = (width ** 2 + height ** 2) ** 0.5;

        const acos = Math.acos(width / z);

        window.requestAnimationFrame(() =>
          setAngle(-1 * acos * (180 / Math.PI))
        );
      }
    };

    calcAngle();

    window.addEventListener("resize", calcAngle);

    return () => window.removeEventListener("resize", calcAngle);
  }, []);

  return (
    <div ref={arrowRef} className={styles.arrowBox}>
      <div className={cn(styles.box9, styles.box)}>
        <Typography variant="h5" color="white">
          В среднем за 4 года junior-разработчик вырастает до senior
        </Typography>
        <div
          className={styles.arrowRoot}
          style={{
            transform: `rotate(${angle}deg)`,
          }}
        >
          <div
            className={styles.arrow}
            style={{
              width: `${arrowWidth}%`,
            }}
          >
            <img src={ArrowIcon} alt="arrow" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const StatisticsBlock: React.FC = () => {
  return (
    <Container>
      <Typography variant="h3" animate>
        Слово цифрам
      </Typography>

      <Carousel className={styles.carousel}>
        <Box1 />
        <Box2 />
        <Box3 />
        <Box4 />
        <Box5 />
        <Box6 />
        <Box7 />
        <Box8 />
        <Box9 />
      </Carousel>

      <div className={styles.wrapper}>
        <Box1 />
        <Box2 />
        <Box3 />
        <Box4 />
        <Box5 />
        <Box6 />
        <Box7 />
        <Box8 />
        <Box9 />
      </div>
    </Container>
  );
};
